<template>
  <b-card title="Reintervention" sub-title="Information about reintervention">
    <b-row>
      <b-col md="6">
        <b-form-group label="---" label-for="---" label-cols-md="4">
          <v-select
            label="value"
            :options="options"
            v-model="patientInfo.follow_up_reintervention"
            :disabled="readOnly"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="MV">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Reintervention"
                    label-for="follow_up_reintervention_mv"
                    label-cols-md="4"
                  >
                    <v-select
                      label="value"
                      :options="options"
                      v-model="patientInfo.follow_up_reintervention_mv"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Reason"
                    label-for="follow_up_reintervention_mv_reason"
                    label-cols-md="4"
                  >
                    <b-form-textarea
                      id="follow_up_reintervention_mv_reason"
                      v-model="patientInfo.follow_up_reintervention_mv_reason"
                      rows="3"
                      placeholder="Reason"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Type"
                    label-for="follow_up_reintervention_mv_type"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="follow_up_reintervention_mv_type"
                      v-model="patientInfo.follow_up_reintervention_mv_type"
                      placeholder="Type"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" class="mt-1">
                  <b-form-group
                    label="Catheterbased"
                    label-for="follow_up_reintervention_mv_catheterbased"
                    label-cols-md="4"
                  >
                    <v-select
                      label="value"
                      :options="options"
                      v-model="
                        patientInfo.follow_up_reintervention_mv_catheterbased
                      "
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Date"
                    label-for="follow_up_reintervention_mv_catheterbased_date"
                    label-cols-md="4"
                  >
                    <b-input-group>
                      <b-form-input
                        id="follow_up_reintervention_mv_catheterbased_date"
                        ref="follow_up_reintervention_mv_catheterbased_date"
                        v-model="
                          patientInfo.follow_up_reintervention_mv_catheterbased_date
                        "
                        type="text"
                        placeholder="DD.MM.YYYY"
                        v-mask="dateMask"
                        autocomplete="off"
                        show-decade-nav
                        :readonly="readOnly"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="date1"
                          value-as-date
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="de"
                          aria-controls="example-input"
                          :disabled="readOnly"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Interval"
                    label-for="follow_up_reintervention_mv_catheterbased_interval"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{
                        patientInfo.follow_up_reintervention_mv_catheterbased_interval
                      }}
                    </div>
                  </b-form-group>
                </b-col>

                <b-col cols="12" class="mt-1">
                  <b-form-group
                    label="Surgery"
                    label-for="follow_up_reintervention_mv_surgery"
                    label-cols-md="4"
                  >
                    <v-select
                      label="value"
                      :options="options"
                      v-model="patientInfo.follow_up_reintervention_mv_surgery"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Date"
                    label-for="follow_up_reintervention_mv_surgery_date"
                    label-cols-md="4"
                  >
                    <b-input-group>
                      <b-form-input
                        id="follow_up_reintervention_mv_surgery_date"
                        ref="follow_up_reintervention_mv_surgery_date"
                        v-model="
                          patientInfo.follow_up_reintervention_mv_surgery_date
                        "
                        type="text"
                        placeholder="DD.MM.YYYY"
                        v-mask="dateMask"
                        autocomplete="off"
                        show-decade-nav
                        :readonly="readOnly"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="date2"
                          value-as-date
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="de"
                          aria-controls="example-input"
                          :disabled="readOnly"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Interval"
                    label-for="follow_up_reintervention_mv_surgery_interval"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{
                        patientInfo.follow_up_reintervention_mv_surgery_interval
                      }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="TV">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Reintervention"
                    label-for="follow_up_reintervention_tv"
                    label-cols-md="4"
                  >
                    <v-select
                      label="value"
                      :options="options"
                      v-model="patientInfo.follow_up_reintervention_tv"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Reason"
                    label-for="follow_up_reintervention_tv_reason"
                    label-cols-md="4"
                  >
                    <b-form-textarea
                      id="follow_up_reintervention_tv_reason"
                      v-model="patientInfo.follow_up_reintervention_tv_reason"
                      rows="3"
                      placeholder="Reason"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Type"
                    label-for="follow_up_reintervention_tv_type"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="follow_up_reintervention_tv_type"
                      v-model="patientInfo.follow_up_reintervention_tv_type"
                      placeholder="Type"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" class="mt-1">
                  <b-form-group
                    label="Catheterbased"
                    label-for="follow_up_reintervention_tv_catheterbased"
                    label-cols-md="4"
                  >
                    <v-select
                      label="value"
                      :options="options"
                      v-model="
                        patientInfo.follow_up_reintervention_tv_catheterbased
                      "
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Date"
                    label-for="follow_up_reintervention_tv_catheterbased_date"
                    label-cols-md="4"
                  >
                    <b-input-group>
                      <b-form-input
                        id="follow_up_reintervention_tv_catheterbased_date"
                        ref="follow_up_reintervention_tv_catheterbased_date"
                        v-model="
                          patientInfo.follow_up_reintervention_tv_catheterbased_date
                        "
                        type="text"
                        placeholder="DD.MM.YYYY"
                        v-mask="dateMask"
                        autocomplete="off"
                        show-decade-nav
                        :readonly="readOnly"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="date3"
                          value-as-date
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="de"
                          aria-controls="example-input"
                          :disabled="readOnly"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Interval"
                    label-for="follow_up_reintervention_mv_catheterbased_interval"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{
                        patientInfo.follow_up_reintervention_tv_catheterbased_interval
                      }}
                    </div>
                  </b-form-group>
                </b-col>

                <b-col cols="12" class="mt-1">
                  <b-form-group
                    label="Surgery"
                    label-for="follow_up_reintervention_tv_surgery"
                    label-cols-md="4"
                  >
                    <v-select
                      label="value"
                      :options="options"
                      v-model="patientInfo.follow_up_reintervention_tv_surgery"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Date"
                    label-for="follow_up_reintervention_tv_surgery_date"
                    label-cols-md="4"
                  >
                    <b-input-group>
                      <b-form-input
                        id="follow_up_reintervention_tv_surgery_date"
                        ref="follow_up_reintervention_tv_surgery_date"
                        v-model="
                          patientInfo.follow_up_reintervention_tv_surgery_date
                        "
                        type="text"
                        placeholder="DD.MM.YYYY"
                        v-mask="dateMask"
                        autocomplete="off"
                        show-decade-nav
                        :readonly="readOnly"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="date4"
                          value-as-date
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="de"
                          aria-controls="example-input"
                          :disabled="readOnly"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Interval"
                    label-for="follow_up_reintervention_tv_surgery_interval"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{
                        patientInfo.follow_up_reintervention_tv_surgery_interval
                      }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { convertDate2Str, dateMask, subtractDate } from "@core/utils/utils";
import { mapState } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        follow_up_reintervention: "",
        follow_up_reintervention_mv: "",
        follow_up_reintervention_mv_catheterbased: "",
        follow_up_reintervention_mv_catheterbased_date: "",
        follow_up_reintervention_mv_catheterbased_interval: "",
        follow_up_reintervention_mv_surgery: "",
        follow_up_reintervention_mv_surgery_date: "",
        follow_up_reintervention_mv_surgery_interval: "",
        follow_up_reintervention_mv_reason: "",
        follow_up_reintervention_mv_type: "",
        follow_up_reintervention_tv: "",
        follow_up_reintervention_tv_catheterbased: "",
        follow_up_reintervention_tv_catheterbased_date: "",
        follow_up_reintervention_tv_catheterbased_interval: "",
        follow_up_reintervention_tv_surgery: "",
        follow_up_reintervention_tv_surgery_date: "",
        follow_up_reintervention_tv_surgery_interval: "",
        follow_up_reintervention_tv_reason: "",
        follow_up_reintervention_tv_type: "",
      },
      options: ["yes", "no"],
      date1: null,
      date2: null,
      date3: null,
      date4: null,
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
    follow_up_reintervention_mv_catheterbased_interval() {
      return subtractDate(
        this.patient.procedure_date,
        this.patientInfo.follow_up_reintervention_mv_catheterbased_date,
        "day"
      );
    },
    follow_up_reintervention_mv_surgery_interval() {
      return subtractDate(
        this.patient.procedure_date,
        this.patientInfo.follow_up_reintervention_mv_surgery_date,
        "day"
      );
    },
    follow_up_reintervention_tv_catheterbased_interval() {
      return subtractDate(
        this.patient.procedure_date,
        this.patientInfo.follow_up_reintervention_tv_catheterbased_date,
        "day"
      );
    },
    follow_up_reintervention_tv_surgery_interval() {
      return subtractDate(
        this.patient.procedure_date,
        this.patientInfo.follow_up_reintervention_tv_surgery_date,
        "day"
      );
    },
  },
  watch: {
    date1(v, ov) {
      if (v) {
        this.patientInfo.follow_up_reintervention_mv_catheterbased_date =
          convertDate2Str(v);
      }
    },
    date2(v, ov) {
      if (v) {
        this.patientInfo.follow_up_reintervention_mv_surgery_date =
          convertDate2Str(v);
      }
    },
    date3(v, ov) {
      if (v) {
        this.patientInfo.follow_up_reintervention_tv_catheterbased_date =
          convertDate2Str(v);
      }
    },
    date4(v, ov) {
      if (v) {
        this.patientInfo.follow_up_reintervention_tv_surgery_date =
          convertDate2Str(v);
      }
    },
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
    follow_up_reintervention_mv_catheterbased_interval(v, ov) {
      this.patientInfo.follow_up_reintervention_mv_catheterbased_interval = v;
    },
    follow_up_reintervention_mv_surgery_interval(v, ov) {
      this.patientInfo.follow_up_reintervention_mv_surgery_interval = v;
    },
    follow_up_reintervention_tv_catheterbased_interval(v, ov) {
      this.patientInfo.follow_up_reintervention_tv_catheterbased_interval = v;
    },
    follow_up_reintervention_tv_surgery_interval(v, ov) {
      this.patientInfo.follow_up_reintervention_tv_surgery_interval = v;
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.follow_up_reintervention = v.follow_up_reintervention;
      this.patientInfo.follow_up_reintervention_mv =
        v.follow_up_reintervention_mv;
      this.patientInfo.follow_up_reintervention_mv_catheterbased =
        v.follow_up_reintervention_mv_catheterbased;

      if (
        document.activeElement !=
        this.$refs.follow_up_reintervention_mv_catheterbased_date.$el
      ) {
        this.patientInfo.follow_up_reintervention_mv_catheterbased_date =
          v.follow_up_reintervention_mv_catheterbased_date;
      }

      this.patientInfo.follow_up_reintervention_mv_catheterbased_interval =
        v.follow_up_reintervention_mv_catheterbased_interval;
      this.patientInfo.follow_up_reintervention_mv_surgery =
        v.follow_up_reintervention_mv_surgery;

      if (
        document.activeElement !=
        this.$refs.follow_up_reintervention_mv_surgery_date.$el
      ) {
        this.patientInfo.follow_up_reintervention_mv_surgery_date =
          v.follow_up_reintervention_mv_surgery_date;
      }

      this.patientInfo.follow_up_reintervention_mv_surgery_interval =
        v.follow_up_reintervention_mv_surgery_interval;
      this.patientInfo.follow_up_reintervention_mv_reason =
        v.follow_up_reintervention_mv_reason;
      this.patientInfo.follow_up_reintervention_mv_type =
        v.follow_up_reintervention_mv_type;
      this.patientInfo.follow_up_reintervention_tv =
        v.follow_up_reintervention_tv;
      this.patientInfo.follow_up_reintervention_tv_catheterbased =
        v.follow_up_reintervention_tv_catheterbased;

      if (
        document.activeElement !=
        this.$refs.follow_up_reintervention_tv_catheterbased_date.$el
      ) {
        this.patientInfo.follow_up_reintervention_tv_catheterbased_date =
          v.follow_up_reintervention_tv_catheterbased_date;
      }

      this.patientInfo.follow_up_reintervention_tv_catheterbased_interval =
        v.follow_up_reintervention_tv_catheterbased_interval;
      this.patientInfo.follow_up_reintervention_tv_surgery =
        v.follow_up_reintervention_tv_surgery;

      if (
        document.activeElement !=
        this.$refs.follow_up_reintervention_tv_surgery_date.$el
      ) {
        this.patientInfo.follow_up_reintervention_tv_surgery_date =
          v.follow_up_reintervention_tv_surgery_date;
      }

      this.patientInfo.follow_up_reintervention_tv_surgery_interval =
        v.follow_up_reintervention_tv_surgery_interval;
      this.patientInfo.follow_up_reintervention_tv_reason =
        v.follow_up_reintervention_tv_reason;
      this.patientInfo.follow_up_reintervention_tv_type =
        v.follow_up_reintervention_tv_type;
    },
  },
};
</script>

<style>
</style>
