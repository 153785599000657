<template>
  <b-card
    title="Major adverse events"
    sub-title="Information about major adverse events"
  >
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Last follow up"
              label-for="follow_up_last_follow_up_date"
              label-cols-md="4"
            >
              <b-input-group>
                <b-form-input
                  id="follow_up_last_follow_up_date"
                  ref="follow_up_last_follow_up_date"
                  v-model="patientInfo.follow_up_last_follow_up_date"
                  type="text"
                  placeholder="DD.MM.YYYY"
                  v-mask="dateMask"
                  autocomplete="off"
                  show-decade-nav
                  :readonly="readOnly"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="date1"
                    value-as-date
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="de"
                    aria-controls="example-input"
                    :disabled="readOnly"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="Death">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      label="value"
                      :options="options"
                      v-model="patientInfo.follow_up_death"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Date"
                    label-for="follow_up_death_date_of_death"
                    label-cols-md="4"
                  >
                    <b-input-group>
                      <b-form-input
                        id="follow_up_death_date_of_death"
                        ref="follow_up_death_date_of_death"
                        v-model="patientInfo.follow_up_death_date_of_death"
                        type="text"
                        placeholder="DD.MM.YYYY"
                        v-mask="dateMask"
                        autocomplete="off"
                        show-decade-nav
                        :readonly="readOnly"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="date2"
                          value-as-date
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="de"
                          aria-controls="example-input"
                          :disabled="readOnly"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card
              class="bordered-card"
              title="Valve related rehospitalisation"
            >
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      label="value"
                      :options="options"
                      v-model="
                        patientInfo.follow_up_valve_related_rehospitalisation
                      "
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Date"
                    label-for="follow_up_valve_related_rehospitalisation_date"
                    label-cols-md="4"
                  >
                    <b-input-group>
                      <b-form-input
                        id="follow_up_valve_related_rehospitalisation_date"
                        ref="follow_up_valve_related_rehospitalisation_date"
                        v-model="
                          patientInfo.follow_up_valve_related_rehospitalisation_date
                        "
                        type="text"
                        placeholder="DD.MM.YYYY"
                        v-mask="dateMask"
                        autocomplete="off"
                        show-decade-nav
                        :readonly="readOnly"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="date3"
                          value-as-date
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="de"
                          aria-controls="example-input"
                          :disabled="readOnly"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Interval"
                    label-for="follow_up_valve_related_rehospitalisation_interval"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{
                        patientInfo.follow_up_valve_related_rehospitalisation_interval
                      }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="Myocardial infarction">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      label="value"
                      :options="options"
                      v-model="patientInfo.follow_up_myocardial_infarction"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Date"
                    label-for="follow_up_myocardial_infarction_date"
                    label-cols-md="4"
                  >
                    <b-input-group>
                      <b-form-input
                        id="follow_up_myocardial_infarction_date"
                        ref="follow_up_myocardial_infarction_date"
                        v-model="
                          patientInfo.follow_up_myocardial_infarction_date
                        "
                        type="text"
                        placeholder="DD.MM.YYYY"
                        v-mask="dateMask"
                        autocomplete="off"
                        show-decade-nav
                        :readonly="readOnly"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="date4"
                          value-as-date
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="de"
                          aria-controls="example-input"
                          :disabled="readOnly"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Interval"
                    label-for="follow_up_myocardial_infarction_interval"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{ patientInfo.follow_up_myocardial_infarction_interval }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="Stroke">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      label="value"
                      :options="options"
                      v-model="patientInfo.follow_up_stroke"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Date"
                    label-for="follow_up_stroke_date"
                    label-cols-md="4"
                  >
                    <b-input-group>
                      <b-form-input
                        id="follow_up_stroke_date"
                        ref="follow_up_stroke_date"
                        v-model="patientInfo.follow_up_stroke_date"
                        type="text"
                        placeholder="DD.MM.YYYY"
                        v-mask="dateMask"
                        autocomplete="off"
                        show-decade-nav
                        :readonly="readOnly"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="date5"
                          value-as-date
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="de"
                          aria-controls="example-input"
                          :disabled="readOnly"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Interval"
                    label-for="follow_up_stroke_interval"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{ patientInfo.follow_up_stroke_interval }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="Renal failure">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      label="value"
                      :options="options"
                      v-model="patientInfo.follow_up_renal_failure"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Date"
                    label-for="follow_up_renal_failure_date"
                    label-cols-md="4"
                  >
                    <b-input-group>
                      <b-form-input
                        id="follow_up_renal_failure_date"
                        ref="follow_up_renal_failure_date"
                        v-model="patientInfo.follow_up_renal_failure_date"
                        type="text"
                        placeholder="DD.MM.YYYY"
                        v-mask="dateMask"
                        autocomplete="off"
                        show-decade-nav
                        :readonly="readOnly"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="date6"
                          value-as-date
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="de"
                          aria-controls="example-input"
                          :disabled="readOnly"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Interval"
                    label-for="follow_up_renal_failure_interval"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{ patientInfo.follow_up_renal_failure_interval }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="New dialysis">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      label="value"
                      :options="options"
                      v-model="patientInfo.follow_up_new_dialysis"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Endocarditis with surgery"
                    label-for="follow_up_endocarditis_with_surgery"
                    label-cols-md="4"
                  >
                    <v-select
                      label="value"
                      :options="options"
                      v-model="patientInfo.follow_up_endocarditis_with_surgery"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="MAE combined">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      label="value"
                      :options="options"
                      v-model="patientInfo.follow_up_MAE_combined"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Date"
                    label-for="follow_up_MAE_combined_date"
                    label-cols-md="4"
                  >
                    <b-input-group>
                      <b-form-input
                        id="follow_up_MAE_combined_date"
                        ref="follow_up_MAE_combined_date"
                        v-model="patientInfo.follow_up_MAE_combined_date"
                        type="text"
                        placeholder="DD.MM.YYYY"
                        v-mask="dateMask"
                        autocomplete="off"
                        show-decade-nav
                        :readonly="readOnly"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="date7"
                          value-as-date
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="de"
                          aria-controls="example-input"
                          :disabled="readOnly"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Interval"
                    label-for="follow_up_MAE_combined_interval"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{ patientInfo.follow_up_MAE_combined_interval }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { convertDate2Str, dateMask, subtractDate } from "@core/utils/utils";
import { mapState } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        follow_up_last_follow_up_date: "",
        follow_up_death: "",
        follow_up_death_date_of_death: "",
        follow_up_valve_related_rehospitalisation: "",
        follow_up_valve_related_rehospitalisation_date: "",
        follow_up_valve_related_rehospitalisation_interval: "",
        follow_up_myocardial_infarction: "",
        follow_up_myocardial_infarction_date: "",
        follow_up_myocardial_infarction_interval: "",
        follow_up_stroke: "",
        follow_up_stroke_date: "",
        follow_up_stroke_interval: "",
        follow_up_renal_failure: "",
        follow_up_renal_failure_date: "",
        follow_up_renal_failure_interval: "",
        follow_up_new_dialysis: "",
        follow_up_endocarditis_with_surgery: "",
        follow_up_MAE_combined: "",
        follow_up_MAE_combined_date: "",
        follow_up_MAE_combined_interval: "",
      },
      value: "",
      options: ["yes", "no"],
      date1: null,
      date2: null,
      date3: null,
      date4: null,
      date5: null,
      date6: null,
      date7: null,
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
    follow_up_valve_related_rehospitalisation_interval() {
      return subtractDate(
        this.patient.procedure_date,
        this.patientInfo.follow_up_valve_related_rehospitalisation_date,
        "day"
      );
    },
    follow_up_myocardial_infarction_interval() {
      return subtractDate(
        this.patient.procedure_date,
        this.patientInfo.follow_up_myocardial_infarction_date,
        "day"
      );
    },
    follow_up_stroke_interval() {
      return subtractDate(
        this.patient.procedure_date,
        this.patientInfo.follow_up_stroke_date,
        "day"
      );
    },
    follow_up_renal_failure_interval() {
      return subtractDate(
        this.patient.procedure_date,
        this.patientInfo.follow_up_renal_failure_date,
        "day"
      );
    },
    follow_up_MAE_combined_interval() {
      return subtractDate(
        this.patient.procedure_date,
        this.patientInfo.follow_up_MAE_combined_date,
        "day"
      );
    },
  },
  watch: {
    date1(v, ov) {
      if (v) {
        this.patientInfo.follow_up_last_follow_up_date = convertDate2Str(v);
      }
    },
    date2(v, ov) {
      if (v) {
        this.patientInfo.follow_up_death_date_of_death = convertDate2Str(v);
      }
    },
    date3(v, ov) {
      if (v) {
        this.patientInfo.follow_up_valve_related_rehospitalisation_date =
          convertDate2Str(v);
      }
    },
    date4(v, ov) {
      if (v) {
        this.patientInfo.follow_up_myocardial_infarction_date =
          convertDate2Str(v);
      }
    },
    date5(v, ov) {
      if (v) {
        this.patientInfo.follow_up_stroke_date = convertDate2Str(v);
      }
    },
    date6(v, ov) {
      if (v) {
        this.patientInfo.follow_up_renal_failure_date = convertDate2Str(v);
      }
    },
    date7(v, ov) {
      if (v) {
        this.patientInfo.follow_up_MAE_combined_date = convertDate2Str(v);
      }
    },
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
    follow_up_valve_related_rehospitalisation_interval(v, ov) {
      this.patientInfo.follow_up_valve_related_rehospitalisation_interval = v;
    },
    follow_up_myocardial_infarction_interval(v, ov) {
      this.patientInfo.follow_up_myocardial_infarction_interval = v;
    },
    follow_up_stroke_interval(v, ov) {
      this.patientInfo.follow_up_stroke_interval = v;
    },
    follow_up_renal_failure_interval(v, ov) {
      this.patientInfo.follow_up_renal_failure_interval = v;
    },
    follow_up_MAE_combined_interval(v, ov) {
      this.patientInfo.follow_up_MAE_combined_interval = v;
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.follow_up_last_follow_up_date =
        v.follow_up_last_follow_up_date;
      this.patientInfo.follow_up_death = v.follow_up_death;

      if (
        document.activeElement != this.$refs.follow_up_death_date_of_death.$el
      ) {
        this.patientInfo.follow_up_death_date_of_death =
          v.follow_up_death_date_of_death;
      }

      this.patientInfo.follow_up_valve_related_rehospitalisation =
        v.follow_up_valve_related_rehospitalisation;

      if (
        document.activeElement !=
        this.$refs.follow_up_valve_related_rehospitalisation_date.$el
      ) {
        this.patientInfo.follow_up_valve_related_rehospitalisation_date =
          v.follow_up_valve_related_rehospitalisation_date;
      }

      this.patientInfo.follow_up_valve_related_rehospitalisation_interval =
        v.follow_up_valve_related_rehospitalisation_interval;
      this.patientInfo.follow_up_myocardial_infarction =
        v.follow_up_myocardial_infarction;

      if (
        document.activeElement !=
        this.$refs.follow_up_myocardial_infarction_date.$el
      ) {
        this.patientInfo.follow_up_myocardial_infarction_date =
          v.follow_up_myocardial_infarction_date;
      }

      this.patientInfo.follow_up_myocardial_infarction_interval =
        v.follow_up_myocardial_infarction_interval;
      this.patientInfo.follow_up_stroke = v.follow_up_stroke;

      if (document.activeElement != this.$refs.follow_up_stroke_date.$el) {
        this.patientInfo.follow_up_stroke_date = v.follow_up_stroke_date;
      }

      this.patientInfo.follow_up_stroke_interval = v.follow_up_stroke_interval;
      this.patientInfo.follow_up_renal_failure = v.follow_up_renal_failure;

      if (
        document.activeElement != this.$refs.follow_up_renal_failure_date.$el
      ) {
        this.patientInfo.follow_up_renal_failure_date =
          v.follow_up_renal_failure_date;
      }

      this.patientInfo.follow_up_renal_failure_interval =
        v.follow_up_renal_failure_interval;
      this.patientInfo.follow_up_new_dialysis = v.follow_up_new_dialysis;
      this.patientInfo.follow_up_endocarditis_with_surgery =
        v.follow_up_endocarditis_with_surgery;
      this.patientInfo.follow_up_MAE_combined = v.follow_up_MAE_combined;

      if (
        document.activeElement != this.$refs.follow_up_MAE_combined_date.$el
      ) {
        this.patientInfo.follow_up_MAE_combined_date =
          v.follow_up_MAE_combined_date;
      }

      this.patientInfo.follow_up_MAE_combined_interval =
        v.follow_up_MAE_combined_interval;
    },
  },
};
</script>

<style>
</style>
