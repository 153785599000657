<template>
  <b-card title="Echo" sub-title="Information about echo">
    <b-row>
      <b-col md="4">
        <b-row>
          <b-col cols="12">
            <b-form-group label="LVEF" label-for="lvef" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.follow_up_echo_LVEF"
                id="lvef"
                type="number"
                placeholder="LVEF"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="LVEDD" label-for="lvedd" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.follow_up_echo_LVEDD"
                id="lvedd"
                type="number"
                placeholder="LVEDD"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="RVEDD" label-for="rvedd" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.follow_up_echo_RVEDD"
                id="rvedd"
                type="number"
                placeholder="RVEDD"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="FAC RV" label-for="fac_rv" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.follow_up_echo_FAC_RV"
                id="fac_rv"
                type="number"
                placeholder="FAC RV"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="FAC LV" label-for="fac_lv" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.follow_up_echo_FAC_LV"
                id="fac_lv"
                type="number"
                placeholder="FAC LV"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="LVESVI" label-for="lvesvi" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.follow_up_echo_LVESVI"
                id="lvesvi"
                type="number"
                placeholder="LVESVI"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="TEI index"
              label-for="tei_index"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.follow_up_echo_TEI_index"
                id="tei_index"
                type="number"
                placeholder="TEI index"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="pHTN > 60mmHg"
              label-for="pHTN"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.follow_up_echo_phtn_over60mmhg"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="AV">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="AV aortic insuff"
                    label-for="av"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.follow_up_echo_av_aortic_insuff"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Grade"
                    label-for="grade"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.follow_up_echo_av_aortic_insuff_grade
                      "
                      id="grade"
                      type="number"
                      placeholder="Grade"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4">
        <b-card class="bordered-card" title="TV">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="TR grade"
                label-for="tr_grade"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_tv_TR_grade"
                  id="tr_grade"
                  type="number"
                  placeholder="TR grade"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="PA pressure systolic"
                label-for="pa_pressure"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_tv_PA_pressure_systolic"
                  id="pa_pressure"
                  type="number"
                  placeholder="PA pressure systolic"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="VCL diameter[cm]"
                label-for="vcl_d"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_tv_VCI_diameter_cm"
                  id="vcl_d"
                  type="number"
                  placeholder="VCL diameter[cm]"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="TAPSE" label-for="tapse" label-cols-md="4">
                <b-form-input
                  v-model="patientInfo.follow_up_echo_tv_TAPSE"
                  id="tapse"
                  type="number"
                  placeholder="TAPSE"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="TR Pmax [mmHg]"
                label-for="tr_pmax"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_tv_TR_pmax_mmhg"
                  id="tr_pmax"
                  type="number"
                  placeholder="TR Pmax [mmHg]"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="TR Vmax"
                label-for="tr_vmax"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_tv_TR_vmax"
                  id="tr_vmax"
                  type="number"
                  placeholder="TR Vmax"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="TR peak velocity"
                label-for="tr_velocity"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_tv_TR_peak_velocity_ms"
                  id="tr_velocity"
                  type="number"
                  placeholder="TR peak velocity"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="TDIS velocity TR annulus"
                label-for="tdis"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="
                    patientInfo.follow_up_echo_tv_TDI_S_velocity_TR_annulus
                  "
                  id="tdis"
                  type="number"
                  placeholder="TDIS velocity TR annulus"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Stroke work index"
                label-for="stroke_work"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_tv_stroke_work_index"
                  id="stroke_work"
                  type="number"
                  placeholder="Stroke work index"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Tricusp annulus diameter"
                label-for="tricusp"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="
                    patientInfo.follow_up_echo_tv_tricusp_annulus_diameter
                  "
                  id="tricusp"
                  type="number"
                  placeholder="Tricusp annulus diameter"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card class="bordered-card" title="MV">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="MR grade"
                label-for="mr_grade"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_mv_MR_grade"
                  id="mr_grade"
                  type="number"
                  placeholder="MR grade"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MV diameter"
                label-for="mv_diameter"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_mv_MV_diameter"
                  id="mv_diameter"
                  type="number"
                  placeholder="MV diameter"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR Pmax"
                label-for="mr_pmax"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_mv_MR_pmax"
                  id="mr_pmax"
                  type="number"
                  placeholder="MR Pmax"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MV Pmax"
                label-for="mv_pmax"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_mv_MR_vmax"
                  id="mv_pmax"
                  type="number"
                  placeholder="MV Pmax"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR peak velocity[ms]"
                label-for="mr_velocity"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_mv_MR_peak_velocity_ms"
                  id="mr_velocity"
                  type="number"
                  placeholder="MR peak velocity[ms]"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR vena contracta"
                label-for="mr_contracta"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_mv_MR_vena_contracta"
                  id="mr_contracta"
                  type="number"
                  placeholder="MR vena contracta"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR EROA"
                label-for="mr_eroa"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_mv_MR_EROA"
                  id="mr_eroa"
                  type="number"
                  placeholder="MR EROA"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR PISA"
                label-for="mr_pisa"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_mv_MR_PISA"
                  id="mr_pisa"
                  type="number"
                  placeholder="MR PISA"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR meangradient"
                label-for="mr_meangradient"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.follow_up_echo_mv_MR_meangradient"
                  id="mr_meangradient"
                  type="number"
                  placeholder="MR meangradient"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="TDIS velocity MR annulus"
                label-for="tdis"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="
                    patientInfo.follow_up_echo_mv_TDI_S_velocity_MR_annulus
                  "
                  id="tdis"
                  type="number"
                  placeholder="TDIS velocity MR annulus"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        follow_up_echo_LVEF: "",
        follow_up_echo_LVEDD: "",
        follow_up_echo_RVEDD: "",
        follow_up_echo_FAC_RV: "",
        follow_up_echo_FAC_LV: "",
        follow_up_echo_LVESVI: "",
        follow_up_echo_TEI_index: "",
        follow_up_echo_av_aortic_insuff: "",
        follow_up_echo_av_aortic_insuff_grade: "",
        follow_up_echo_phtn_over60mmhg: "",
        follow_up_echo_tv_TR_grade: "",
        follow_up_echo_tv_PA_pressure_systolic: "",
        follow_up_echo_tv_VCI_diameter_cm: "",
        follow_up_echo_tv_TAPSE: "",
        follow_up_echo_tv_TR_pmax_mmhg: "",
        follow_up_echo_tv_TR_vmax: "",
        follow_up_echo_tv_TR_peak_velocity_ms: "",
        follow_up_echo_tv_TDI_S_velocity_TR_annulus: "",
        follow_up_echo_tv_stroke_work_index: "",
        follow_up_echo_tv_tricusp_annulus_diameter: "",
        follow_up_echo_mv_MR_grade: "",
        follow_up_echo_mv_MV_diameter: "",
        follow_up_echo_mv_MR_pmax: "",
        follow_up_echo_mv_MR_vmax: "",
        follow_up_echo_mv_MR_peak_velocity_ms: "",
        follow_up_echo_mv_MR_vena_contracta: "",
        follow_up_echo_mv_MR_EROA: "",
        follow_up_echo_mv_MR_PISA: "",
        follow_up_echo_mv_MR_meangradient: "",
        follow_up_echo_mv_TDI_S_velocity_MR_annulus: "",
      },
      options: ["yes", "no"],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    setPatientInfo(v) {
      this.patientInfo._id = v._id;
      this.patientInfo.follow_up_echo_LVEF = v.follow_up_echo_LVEF;
      this.patientInfo.follow_up_echo_LVEDD = v.follow_up_echo_LVEDD;
      this.patientInfo.follow_up_echo_RVEDD = v.follow_up_echo_RVEDD;
      this.patientInfo.follow_up_echo_FAC_RV = v.follow_up_echo_FAC_RV;
      this.patientInfo.follow_up_echo_FAC_LV = v.follow_up_echo_FAC_LV;
      this.patientInfo.follow_up_echo_LVESVI = v.follow_up_echo_LVESVI;
      this.patientInfo.follow_up_echo_TEI_index = v.follow_up_echo_TEI_index;
      this.patientInfo.follow_up_echo_av_aortic_insuff =
        v.follow_up_echo_av_aortic_insuff;
      this.patientInfo.follow_up_echo_av_aortic_insuff_grade =
        v.follow_up_echo_av_aortic_insuff_grade;
      this.patientInfo.follow_up_echo_phtn_over60mmhg =
        v.follow_up_echo_phtn_over60mmhg;
      this.patientInfo.follow_up_echo_tv_TR_grade =
        v.follow_up_echo_tv_TR_grade;
      this.patientInfo.follow_up_echo_tv_PA_pressure_systolic =
        v.follow_up_echo_tv_PA_pressure_systolic;
      this.patientInfo.follow_up_echo_tv_VCI_diameter_cm =
        v.follow_up_echo_tv_VCI_diameter_cm;
      this.patientInfo.follow_up_echo_tv_TAPSE = v.follow_up_echo_tv_TAPSE;
      this.patientInfo.follow_up_echo_tv_TR_pmax_mmhg =
        v.follow_up_echo_tv_TR_pmax_mmhg;
      this.patientInfo.follow_up_echo_tv_TR_vmax = v.follow_up_echo_tv_TR_vmax;
      this.patientInfo.follow_up_echo_tv_TR_peak_velocity_ms =
        v.follow_up_echo_tv_TR_peak_velocity_ms;
      this.patientInfo.follow_up_echo_tv_TDI_S_velocity_TR_annulus =
        v.follow_up_echo_tv_TDI_S_velocity_TR_annulus;
      this.patientInfo.follow_up_echo_tv_stroke_work_index =
        v.follow_up_echo_tv_stroke_work_index;
      this.patientInfo.follow_up_echo_tv_tricusp_annulus_diameter =
        v.follow_up_echo_tv_tricusp_annulus_diameter;
      this.patientInfo.follow_up_echo_mv_MR_grade =
        v.follow_up_echo_mv_MR_grade;
      this.patientInfo.follow_up_echo_mv_MV_diameter =
        v.follow_up_echo_mv_MV_diameter;
      this.patientInfo.follow_up_echo_mv_MR_pmax = v.follow_up_echo_mv_MR_pmax;
      this.patientInfo.follow_up_echo_mv_MR_vmax = v.follow_up_echo_mv_MR_vmax;
      this.patientInfo.follow_up_echo_mv_MR_peak_velocity_ms =
        v.follow_up_echo_mv_MR_peak_velocity_ms;
      this.patientInfo.follow_up_echo_mv_MR_vena_contracta =
        v.follow_up_echo_mv_MR_vena_contracta;
      this.patientInfo.follow_up_echo_mv_MR_EROA = v.follow_up_echo_mv_MR_EROA;
      this.patientInfo.follow_up_echo_mv_MR_PISA = v.follow_up_echo_mv_MR_PISA;
      this.patientInfo.follow_up_echo_mv_MR_meangradient =
        v.follow_up_echo_mv_MR_meangradient;
      this.patientInfo.follow_up_echo_mv_TDI_S_velocity_MR_annulus =
        v.follow_up_echo_mv_TDI_S_velocity_MR_annulus;
    },
  },
};
</script>

<style>
</style>
